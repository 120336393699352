import { useEffect, useState } from "react"
import axios from 'axios';
import { WHATSAPP_SERVER_URL } from "../utils/constants";
import { useParams } from "react-router-dom";


const UserLog = () => {
    const params = useParams();

    const [conversations, setConversations] = useState([])

    useEffect(() => {
        axios
          .get(WHATSAPP_SERVER_URL + `conversations/${params.phone}`)
          .then(function (response) {
            setConversations(response.data.reverse());
          })
          .catch(function (error) {
            console.error(error);
          });
    }, [params.phone])

    return (
        <div className="p-10">
            <div className="text-3xl text-center">Conversations with {params.phone}</div>
            {conversations.map(conversation => <div key={conversation.id}>
                <div className="bg-gray-200 px-2 my-5 cursor-pointer hover:underline hover:text-blue-800 hover:bg-yellow-400">Conversation Id: <span>{conversation.id}</span>, Start Time: {new Date(`${conversation.start_time}Z`).toLocaleString("en-US", { timeZone: "Asia/Kolkata" })}</div>
                <table className="table-fixed border-collapse">
                    <thead>
                        <tr className="">
                            <th className="">Time</th>
                            <th className="">Twilio Id</th>
                            <th className="">From</th>
                            <th className="">To</th>
                            <th className="">Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conversation.messages.map(message => {
                            return (
                                <tr className="py-2 cursor-pointer hover:bg-gray-100">
                                    <td className="pl-4 w-[15%]">{new Date(`${message.timestamp}Z`).toLocaleString("en-US", { timeZone: "Asia/Kolkata" })}</td>
                                    <td className="pl-4">{message.twilio_msg_id}</td>
                                    <td className="pl-4">{message.from_customer_name}</td>
                                    <td className="pl-4">{message.to_customer_name}</td>
                                    <td className="pl-4"><div className={`px-4 py-1 my-1 rounded-[10px] text-white ${message.from_customer_name === 'SalesAgent' ? 'bg-[#128c7e]' : 'bg-gray-800'}`}>{message.content}</div></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>)}
        </div>

    )
}

export default UserLog