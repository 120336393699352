import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Logs from './pages/Logs';
import UserLog from './pages/UserLog';
import MakeCallForm from "./components/MakeCallForm";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/:backend",
    element: <App />,
  },
  {
    path: "/logs",
    element: <Logs />,
  },
  {
    path: "/logs/:phone",
    element: <UserLog />,
  },
]);

export default routes