import "./App.css";
import { useNavigate, useParams } from "react-router-dom";
import MakeCallForm from "./components/MakeCallForm";

function App() {
  const params = useParams();

  return (
    <>
      <div className="flex py-4 px-8">
        {/* <div
          className="ml-auto text-blue-600 cursor-pointer hover:underline"
          onClick={() => {
            navigate("/logs");
          }}>
          {" "}
          Logs
        </div> */}
      </div>

      <div className="flex flex-col items-center min-h-screen p-24 space-y-2">
        <h1 className="text-2xl font-bold mb-4">
          Initiate Outbound Call: {params.backend}
        </h1>

        <MakeCallForm backend={params.backend} />
      </div>
    </>
  );
}

export default App;
