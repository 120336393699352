import { useEffect, useState } from "react"
import axios from 'axios';
import { WHATSAPP_SERVER_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";


const Logs = () => {
    const [users, setUsers] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        axios
          .get(WHATSAPP_SERVER_URL + "customers/")
          .then(function (response) {
            response.data = response.data.filter((user) => {
              return user.name !== "SalesAgent";
            });
            setUsers(response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
    }, [])

    return <table className="p-10 m-10 table-auto border-collapse">
        <thead>
            <tr>
                <th className="border-2 border-gray-500">Id</th>
                <th className="border-2 border-gray-500">Name</th>
                <th className="border-2 border-gray-500">Phone no</th>
                <th className="border-2 border-gray-500">Current Conversation Id</th>
            </tr>
        </thead>
        <tbody>
            {
                users.map((user) => {
                    return (<tr className="cursor-pointer hover:underline hover:text-blue-800" key={user.id} onClick={() => { navigate(`${user.phone_no}`) }}>
                        <td className="px-4 border-2 border-gray-500">{user.id}</td>
                        <td className="px-4 border-2 border-gray-500">{user.name}</td>
                        <td className="px-4 border-2 border-gray-500">{user.phone_no}</td>
                        <td className="px-4 border-2 border-gray-500">{user.current_conversation_id}</td>
                    </tr>)
                })}
        </tbody>
    </table>
}

export default Logs