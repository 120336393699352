import axios from "axios";

const apiClient = () => {
  const requestHeaders = { "Content-Type": "application/json" };
  
    requestHeaders[
      "Authorization"
    ] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzUxNjM1NTY1LCJpYXQiOjE3MjAwOTk1NjUsImp0aSI6IjRkN2FiZGY1ZGNjMzQzNDE4MTRhNzBiOWZmZTY2ZGU5IiwidXNlcl9pZCI6MTB9.dmlHohY9NyDx8JoOjod28-2s81RcrmdJE1jjBCkc8B0`;
  
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    responseType: "json",
    headers: requestHeaders,
    timeout: 100000,
  });
  return axiosInstance;
};

export default apiClient;
